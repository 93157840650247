import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Button, Container, Typography} from '@mui/material';

const HomePage: React.FC = () => {
    const navigate = useNavigate();

    return (
        <Container maxWidth="md">
            <Typography variant="h4" component="h1" gutterBottom>
                Welcome to F1 Manager
            </Typography>
            <Button
                variant="contained"
                color="primary"
                onClick={() => navigate('/drivers')}
            >
                Drivers
            </Button>
        </Container>
    );
}

export default HomePage;