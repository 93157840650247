import React, {useEffect, useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {getDrivers} from '../services/api';
import {Driver} from '../types';
import {Avatar, Box, Container, List, ListItem, ListItemButton, Paper, Typography} from '@mui/material';

const DriverListPage: React.FC = () => {
    const [drivers, setDrivers] = useState<Driver[]>([]);

    useEffect(() => {
        async function fetchDrivers() {
            const data = await getDrivers();
            setDrivers(data);
        }

        fetchDrivers();
    }, []);

    return (
        <Container maxWidth="md">
            <Typography variant="h4" component="h1" gutterBottom>
                Driver List
            </Typography>
            <Paper elevation={3}>
                <List>
                    {drivers.map(driver => (
                        <ListItem
                            key={driver.driverNumber}
                            disablePadding
                            secondaryAction={
                                <Avatar
                                    src={driver.headshotUrl}
                                    sx={{width: 60, height: 60}}
                                />
                            }
                        >
                            <ListItemButton component={RouterLink} to={`/drivers/${driver.driverNumber}`}>
                                <Box>
                                    <Typography
                                        variant="subtitle1">{driver.driverNumber} {driver.fullName}</Typography>
                                    <Typography variant="body2" color="text.secondary">{driver.teamName}</Typography>

                                </Box>
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Paper>
        </Container>
    );
}

export default DriverListPage;