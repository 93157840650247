import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {getDriverDetails} from '../services/api';
import {Driver} from '../types';
import {Card, CardContent, CircularProgress, Container, Grid, Typography} from '@mui/material';

const DriverDetailsPage: React.FC = () => {
    const [driver, setDriver] = useState<Driver | null>(null);
    const {id} = useParams<{ id: string }>();

    useEffect(() => {
        async function fetchDriverDetails() {
            if (id) {
                const data = await getDriverDetails(id);
                setDriver(data);
            }
        }

        fetchDriverDetails();
    }, [id]);

    if (!driver) return <Container maxWidth="md"><CircularProgress/></Container>;


    return (
        <Container maxWidth="md">
            <Typography variant="h4" component="h1" gutterBottom>
                Driver Details
            </Typography>
            <Card>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="subtitle1">Name:</Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Typography variant="body1">{driver.firstName}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="subtitle1">ACK:</Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Typography variant="body1">{driver.nameAcronym}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="subtitle1">Team:</Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Typography variant="body1">{driver.teamName}</Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Container>
    );


}

export default DriverDetailsPage;