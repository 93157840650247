import axios from 'axios';
import {Driver} from '../types';

const API_URL = 'http://localhost:8081/api/drivers';

export async function getDrivers(): Promise<Driver[]> {
    const response = await axios.get<Driver[]>(`${API_URL}`);
    return response.data;
}

export async function getDriverDetails(id: string): Promise<Driver> {
    const response = await axios.get<Driver>(`${API_URL}/${id}`);
    return response.data;
}