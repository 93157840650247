import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Layout from './components/Layout';
import HomePage from './pages/HomePage';
import DriverListPage from './pages/DriverListPage';
import DriverDetailsPage from './pages/DriverDetailsPage';

const App: React.FC = () => {
    return (
        <Router>
            <Layout>
                <Routes>
                    <Route path="/" element={<HomePage/>}/>
                    <Route path="/drivers" element={<DriverListPage/>}/>
                    <Route path="/drivers/:id" element={<DriverDetailsPage/>}/>
                </Routes>
            </Layout>
        </Router>
    );
}

export default App;