import React from 'react';
import Navigation from './Navigation';
import {Box, Container} from '@mui/material';

interface LayoutProps {
    children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({children}) => {
    return (
        <Box>
            <Navigation/>
            <Container component="main" sx={{mt: 4}}>
                {children}
            </Container>
        </Box>
    );
}

export default Layout;