import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {AppBar, Button, Toolbar, Typography} from '@mui/material';

const Navigation: React.FC = () => {
    return (
        <AppBar position="static">
            <Toolbar>
                <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                    F1 Manager
                </Typography>
                <Button color="inherit" component={RouterLink} to="/">
                    Home
                </Button>
                <Button color="inherit" component={RouterLink} to="/drivers">
                    Drivers
                </Button>
            </Toolbar>
        </AppBar>
    );
}

export default Navigation;